/* GLOBAL STYLE */
body{background-color:#0e1011;color:#fff;}
img{display:block;}
a{cursor:pointer;}
*{margin:0;padding:0;box-sizing:border-box;}

.section{display:flex;justify-content:center;align-items:center;flex-wrap:wrap;}

/* FOOTER */
.footer_container{background-color:#0c0d0e;width:100%;}
.footer{display:flex;justify-content:center;align-items:center;}
.footer_logo{width:35%;}
.footer_menu{width:15%;}
.footer_menu a{display:block;text-decoration:none;color:#fff;margin-bottom:.2rem;}
.footer_info{width:30%;}
.footer_map{width:20%;}
.footer_menu_element, .footer_info_element{margin-bottom:.2rem;}
.footer_info_element{text-decoration:none;color:#fff;display:block;}
.footer_info_element_sm{margin-bottom:0;margin-top:.4rem;position:relative;width:fit-content;}
.footer_info_element_sm a{position:absolute;width:33%;height:100%;top:0;left:0;}
.footer_info_element_sm a:nth-child(2){left:33%;}
.footer_info_element_sm a:nth-child(3){left:66%;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .footer_container{padding:2rem 1rem;}
    .footer{flex-wrap:wrap;align-items:flex-start;}

    .footer_logo{width:50%;margin-bottom:1rem;}
    .footer_menu{width:50%;}
    .footer_info{width:50%;}
    .footer_map{width:50%;margin-top:1rem;padding-left:1rem;}

    .footer_logo img{width:5rem;}
    .footer_info_element_sm img{width:7rem;height:auto;}
}

@media (max-width:899px) and (min-width: 600px){
    .footer_container{padding:3rem 2rem;}

    .footer_logo{width:22%;}
    .footer_menu{width:21%;}
    .footer_info{width:37%;}
    .footer_map{width:20%;}

    .footer_logo img{width:6rem;}
    .footer_info_element_sm img{width:7rem;height:auto;}
}

@media (max-width:1199px) and (min-width: 900px){
    .footer_container{padding:4rem 3rem;}

    .footer_logo{width:25%;}
    .footer_menu{width:20%;}
    .footer_info{width:35%;}
    .footer_map{width:20%;}
    
    .footer_logo img{width:8rem;}
    .footer_info_element_sm img{width:7rem;height:auto;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .footer_container{padding:4rem}

    .footer_logo img{width:8rem;}
    .footer_info_element_sm img{width:7rem;height:auto;}
}

@media (min-width:1400px){
    .footer_container{padding:4rem}

    .footer_logo{flex-grow:1;}
    .footer_logo img{width:8rem;}
    .footer_info_element_sm img{width:7rem;height:auto;}
    .footer_menu{width:fit-content;padding-right:2rem;}
    .footer_info{width:fit-content;padding-right:2rem;}
    .footer_map{width:10rem;background-color:#fff;height:5rem;}
}