/* GLOBAL STYLE */
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
.header_container{padding-top:6rem;}

.subtitle{position:relative;color:#606060;font-family: 'Karla', sans-serif;font-weight:500;}
.subtitle span{position:absolute;left:0;top:50%;transform:translateY(-50%);background-color:#606060;border-radius:100%;}
.title{color:#fff;font-family: 'Playfair Display', serif;font-weight:400;}
.des{color:#fff;font-family: 'Karla', sans-serif;font-weight:500;}

.des{text-align:justify;}

.header{font-family: 'Karla', sans-serif;font-weight:500;}

.map_title{font-family: 'Playfair Display', serif;font-weight:400;}
.map_address, .map_tel{font-family: 'Karla', sans-serif;font-weight:500;display:block;color:#fff;text-decoration:none;}

.footer{font-family: 'Karla', sans-serif;font-weight:500;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .subtitle{padding-left:1rem;font-size:1rem;}
    .subtitle span{width:.25rem;height:.25rem;}
    
    .title{font-size:2rem;margin-top:.8rem;line-height:2rem;}

    .des{font-size:1.1rem;margin-top:1rem;}

    .map_title{font-size:1.2rem;}
    .map_address, .map_tel{font-size:.9rem;}

    .footer{font-size:.9rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .subtitle{padding-left:1rem;font-size:1.1rem;}
    .subtitle span{width:.3rem;height:.3rem;}

    .title{font-size:2.5rem;max-width:32rem;margin-top:.8rem;line-height:2.5rem;}

    .des{font-size:1.2rem;margin-top:1rem;}

    .map_title{font-size:1.3rem;}
    .map_address, .map_tel{font-size:1rem;}

    .footer{font-size:.9rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .subtitle{padding-left:1rem;font-size:1.1rem;}
    .subtitle span{width:.3rem;height:.3rem;}
    
    .title{font-size:2.3rem;max-width:29.6rem;margin-top:.8rem;line-height:2.5rem;}

    .des{font-size:1.2rem;margin-top:1rem;}

    .header a{font-size:1.3rem;}

    .map_title{font-size:1.3rem;}
    .map_address, .map_tel{font-size:1rem;}

    .footer{font-size:1.1rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .subtitle{padding-left:1rem;font-size:1.1rem;}
    .subtitle span{width:.3rem;height:.3rem;}

    .title{font-size:2.5rem;max-width:33rem;margin-top:.8rem;line-height:2.5rem;}

    .des{font-size:1.2rem;margin-top:1rem;}

    .header a{font-size:1.3rem;}

    .map_title{font-size:1.5rem;}
    .map_address, .map_tel{font-size:1.1rem;}

    .footer{font-size:1.1rem;}
}

@media (min-width:1400px){
    .subtitle{padding-left:1rem;font-size:1.3rem;}
    .subtitle span{width:.3rem;height:.3rem;}

    .title{font-size:3rem;max-width:38.6rem;margin-top:.8rem;line-height:3rem;}

    .des{font-size:1.35rem;margin-top:1rem;}

    .header a{font-size:1.5rem;}
    
    .map_title{font-size:1.7rem;}
    .map_address, .map_tel{font-size:1.2rem;}

    .footer{font-size:1.2rem;}
}