/* HEADER */
.header{position:relative;width:100%;height:auto;}
.headerImage{width:100%;height:auto;}
.headerTitle{position:absolute;top:55%;left:50%;transform:translate(-50%,-50%);font-weight:bold;}
.section{position:relative;}
.element{display:flex;align-items:center;justify-content:center;max-width:1400px;margin:auto;}
.elementDes, .elementImage{width:50%;}
.elementImage img{width:100%;}
.elementDesParagraphTitle{color:#606060;}
.solicitud{text-align:center;}
.solicitudButton{color:#fff;text-decoration:underline;}

/* RESPONSIVE STYLE */
@media (max-width: 599px) {
    .element{padding:2rem 1rem 0 1rem;}
    .header{padding-top:7rem;}
    .headerTitle{font-size:2rem;text-align:center;width:20rem;line-height:2rem;}

    .element{flex-wrap:wrap;}
    .elementDes, .elementImage{width:100%;}
    .elementImage{margin-top:1rem;}
    .section:nth-child(even) .element{flex-direction:column-reverse;}
    .elementDesTitle{font-size:2rem;line-height:2rem;margin-bottom:1.5rem;}
    .elementDesParagraph{font-size:1.1rem;margin-bottom:1rem;}

    .solicitud{padding:2rem 1rem;}
    .solicitudButton{font-size:1.5rem;}
}

@media (max-width:899px) and (min-width: 600px) {
    .element{padding:3rem 2rem 0 2rem;}
    .header{padding-top:5.5rem;}
    .headerTitle{font-size:3.5rem;text-align:center;width:35rem;line-height:4rem;}

    .element{flex-wrap:wrap;}
    .elementDes, .elementImage{width:100%;}
    .elementImage{margin-top:1rem;}
    .section:nth-child(even) .element{flex-direction:column-reverse;}
    .elementDesTitle{font-size:2.2rem;line-height:2rem;margin-bottom:1.5rem;}
    .elementDesParagraph{font-size:1.3rem;margin-bottom:1rem;}

    .solicitud{padding:3rem 2rem;}
    .solicitudButton{font-size:1.5rem;}
}

@media (max-width:1199px) and (min-width: 900px) {
    .element{padding:3rem 3rem 0 3rem;}
    .header{padding-top:6rem;}
    .headerTitle{font-size:4rem;text-align:center;width:40rem;line-height:4rem;}

    .elementDesTitle{font-size:2rem;line-height:2rem;width:20rem;margin-bottom:1.5rem;}
    .elementDesParagraph{font-size:1.2rem;margin-bottom:.8rem;}
    .section:nth-child(odd) .elementImage{padding-left:.8rem;}
    .section:nth-child(odd) .elementDes{padding-right:.8rem;}
    .section:nth-child(even) .elementImage{padding-right:.8rem;}
    .section:nth-child(even) .elementDes{padding-left:.8rem;}

    .solicitud{padding:4rem 3rem;}
    .solicitudButton{font-size:2rem;}
}

@media (max-width:1399px) and (min-width: 1200px) {
    .element{padding:4rem 3rem 0 3rem;}
    .header{padding-top:6rem;}
    .headerTitle{font-size:4rem;text-align:center;width:40rem;line-height:4rem;}

    .elementDesTitle{font-size:2rem;line-height:2rem;width:20rem;margin-bottom:1.5rem;}
    .elementDesParagraph{font-size:1.3rem;margin-bottom:1rem;}
    .section:nth-child(odd) .elementImage{padding-left:1rem;}
    .section:nth-child(odd) .elementDes{padding-right:1rem;}
    .section:nth-child(even) .elementImage{padding-right:1rem;}
    .section:nth-child(even) .elementDes{padding-left:1rem;}

    .solicitud{padding:4rem 3rem;}
    .solicitudButton{font-size:2rem;}
}

@media (min-width:1400px) {
    .element{padding:4rem 4rem 0 4rem;}
    .header{padding-top:6.5rem;}
    .headerTitle{font-size:4rem;text-align:center;width:40rem;line-height:4rem;}
    
    .elementDesTitle{font-size:2rem;line-height:2rem;width:20rem;margin-bottom:1.5rem;}
    .elementDesParagraph{font-size:1.3rem;margin-bottom:1rem;}
    .section:nth-child(odd) .elementImage{padding-left:1rem;}
    .section:nth-child(odd) .elementDes{padding-right:1rem;}
    .section:nth-child(even) .elementImage{padding-right:1rem;}
    .section:nth-child(even) .elementDes{padding-left:1rem;}
    
    .solicitud{padding:4rem;}
    .solicitudButton{font-size:2rem;}
}