* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

.formulario {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11rem 1rem 4rem 1rem;
}

.title {
  font-family: 'Karla';
  font-size: 2.5rem;
  text-align: center;
}

p {
  display: block;
  color: tomato;
  font-size: x-small;
  font: inherit;
  font-family: 'Karla'
}

.flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.flex2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

input,
select,
textarea {
  width: 100%;
  font-family: 'Karla'
}

.form {
  padding: 1rem 1rem;
  width: min(1200px, 90%);
  border-radius: 10px;
}

.form__inputs {
  margin: 2rem 0 1rem;
  display: grid;
  gap: 1.5rem;
  color: #6b6b6b;
}

.form__label {
  width: 100%;
  display: grid;
  grid-template-areas: "input";
}

.form__input {
  grid-area: input;

  width: 100%;
  border-radius: 10px;
  border: 1px solid #d7d0d0;
  padding: 1rem 2rem;
  color: #6b6b6b;
}

.form__text {
  font-family: 'Karla';
  grid-area: input;
  z-index: 100;
  height: 100%;
  margin-left: 1rem;
  padding: 0 .5rem;

  display: flex;
  align-items: center;
  transform-origin: left center;
  transition: transform .3s;
}

.form__input:focus+.form__text,
.form__input:not(:placeholder-shown)+.form__text {
  background-color: #000000;
  border-radius: 8px;
  color: #ffffff;
  transform: translateY(-70%) scale(.6);
  line-height: 1.2;
}

.btn__form {
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  font-family: 'Karla';
  font-size: 1.3rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #606060;
  margin-top: 2rem;
}

.btn__form:hover {
  background-color: #ffffff;
  color: #606060;
  transition: .3s;
}

.terminos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding-top: 1.5rem;
}

.spant {
  font-weight: 900;
}

.terminos p, .terminos .links{
  font-family: 'Karla';
  line-height: 1.5;
  font-size: 1.1rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}

.parrafo {
  max-width: 1300px;
}

.success__form, .error__form{font-family: 'Karla';padding:1rem;border-radius:.5rem;font-size:1.2rem;display:none;}
.success__form{background-color:#051b11;color:#75b798;border:1px solid #75b798;}
.error__form{background-color:#332701;color:#ffda6a;border:1px solid #ffda6a;}

@media(max-width:900px) {
  .flex2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .flex2> :nth-child(3n + 3) {
    grid-column: span 2;
  }

  .form {
    padding: 1rem 0rem;
    width: 100%;
  }
}

@media(max-width:450px) {

  .flex,
  .flex2 {
    gap: 0;
  }

  .flex {
    grid-template-columns: repeat(1, 1fr);
  }

  .flex2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .flex2> :nth-child(3n + 3) {
    grid-column: span 1;
  }

  .form__input {
    padding: 1.5rem 2rem;
  }

  .form__text {
    font-size: .9rem;
    line-height: 1.5;
  }
}

@media(max-width:380px) {
  .title {
    font-size: 2rem;
  }
}