/* GLOBAL STYLE */
.navbar_container{position:absolute;top:0;left:0;width:100%;z-index:99;}
.navbar_object{position:absolute;top:0;width:50%;display:flex;align-items:center;font-family: 'Karla', sans-serif;}
.navbar_left{left:0;justify-content:flex-end;}
.navbar_option{text-decoration:none;color:#fff;}
.navbar_left .navbar_option{padding-left:1rem;}
.navbar_right{left:50%;justify-content:flex-start;}
.navbar_right .navbar_option{padding-right:1rem;}
.navbar_center{position:absolute;top:0;left:50%;height:5rem;}
.navbar_center img{height:100%;width:auto;transform:translateX(-50%);}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .navbar_object{width: 40%;}
    .navbar_right{left:40%;}
    .navbar_object, .navbar_center{height:4rem;padding-top:1rem;}
    .navbar_left{margin-left:.8rem; padding-top:5.5rem;}
    .navbar_left .navbar_option{padding-left:.6rem;}
    .navbar_right .navbar_option{padding-right:.6rem;}
    .navbar_right{padding-left:1.2rem;padding-top:5.5rem;}
    .navbar_option{font-size:.9rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .navbar_container{height:5rem;}

    .navbar_object, .navbar_center{height:4.5rem;padding-top:1rem;}
    .navbar_left{padding-right:3rem;}
    .navbar_right{padding-left:3rem;}
    .navbar_option{font-size:1.1rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .navbar_container{height:5rem;}

    .navbar_object, .navbar_center{height:6rem;padding-top:1rem;padding-bottom:1rem;}
    .navbar_left{padding-right:4rem;}
    .navbar_right{padding-left:4rem;}
    .navbar_option{font-size:1.2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .navbar_container{height:5rem;}

    .navbar_object, .navbar_center{height:5rem;padding-top:1rem;}
    .navbar_left{padding-right:4rem;}
    .navbar_right{padding-left:4rem;}
    .navbar_option{font-size:1.2rem;}
}

@media (min-width:1400px){
    .navbar_container{height:5rem;}

    .navbar_object, .navbar_center{height:5.5rem;padding-top:1rem;}
    .navbar_left{padding-right:5rem;}
    .navbar_right{padding-left:5rem;}
    .navbar_option{font-size:1.5rem;}
}

@media (max-width:375px){
    .navbar_option{font-size:.7rem;}
}