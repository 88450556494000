/* GLOBAL STYLE */
.header_container{position:relative;}
.header_container_logo{display:flex;flex-direction:column;width:100%;align-items:center;}
.header_container_buttons{margin-top:2rem;}
.header_container_buttons a{padding:.5rem 2rem;border:.12rem solid #fff;text-decoration:none;}
.header_container_buttons a:nth-child(1){background-color:#fff;color:#000;margin-right:.5rem;}
.header_container_buttons a:nth-child(2){background-color:#0e1011;color:#fff;margin-left:.5rem;}
.tenedor_r, .tenedor_l{position:absolute;pointer-events:none;}
.tenedor_r{top:0;}
.tenedor_l{left:0;bottom:0;}

/* NOSOTROS */
.nosotros img{width:50%;}
.nosotros_description{width:50%;}

/* CHEF */
.chef img{width:50%;}
.chef_description{width:50%;}

/* SABORES */
.sabores img{width:100%;height:auto;}

/* INSTALACIONES */
.instalaciones img{width:50%;}
.instalaciones_description{width:50%;}

/* MENU */
.menu{align-self:center;}
.menu_description{width:100%;}
.menu_link{flex-grow:1;text-align:right;}
.menu_link a{text-decoration:underline;color:#fff;}
.menu_element{width:25%;padding-right:.5rem;padding-left:.5rem;}
.menu_element img{width:100%;height:auto;}
.menu_element:nth-child(1){margin-top:-3rem;}
.menu_element:nth-child(2){margin-top:-1rem;}
.menu_element:nth-child(3){margin-top:-5rem;}

/* ENCONTRARNOS */
.encontrarnos_container{display:flex;justify-content:center;}
.map_element{width:20rem;text-align:center;padding:0 1rem;}
.map_element img{margin:auto;margin-bottom:1rem;width:3rem;}
.map_element_city{margin-bottom:1rem;}
.map_element_address{margin-bottom:.5rem;}
.map_element_tel{color:#fff;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .header_container{padding:14rem 0 10rem 0;}
    .header_container_logo img{width:80%;}
    .tenedor_r{display:none;}
    .tenedor_l img{width:15rem;height:auto;}

    .nosotros{flex-direction:column-reverse;}
    .nosotros img, .nosotros_description{width:100%;}
    .nosotros_description{padding:3rem 1rem;}

    .chef{flex-wrap:wrap;flex-direction:column;}
    .chef_description{padding:3rem 1rem;}
    .chef_description, .chef img{width:100%;}

    .sabores_description{padding:3rem 1rem;}

    .instalaciones{flex-direction:column-reverse;}
    .instalaciones_description, .instalaciones img{width:100%;}
    .instalaciones_description{padding:3rem 1rem;}
    .instalaciones_description div{margin-top:0;}

    .menu{padding:3rem 1rem;}
    .menu a{font-size:1.2rem;}
    .menu div{width:100%;}
    .menu_elements{padding:2rem 1rem;}
    .menu_element{width:50%;}
    .menu_element:nth-child(1){padding-left:0;padding-right:.5rem;margin-top:-3rem;}
    .menu_element:nth-child(2){padding-left:.5rem;padding-right:0;}
    .menu_element:nth-child(3){padding-left:0;padding-right:.5rem;margin-top:-2rem;}
    .menu_element:nth-child(4){padding-left:.5rem;padding-right:0;margin-top:2rem;}
    .menu_element_title{font-size:1.2rem;margin-top:.2rem;}
    .menu_link{text-align:left;margin-top:1rem;}

    .encontrarnos{padding:2rem 3rem 3rem 3rem;}
    .encontrarnos_container{margin-bottom:3rem;flex-wrap:wrap;}
    .map_element{width:calc(50% - .5rem);}
    .map_element:nth-child(3){margin-top:2rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .header_container{padding:12rem 0;}
    .header_container_logo img{width:55%;}
    .tenedor_r{display:none;}
    .tenedor_l img{width:20rem;height:auto;}

    .nosotros{flex-direction:column-reverse;}
    .nosotros img, .nosotros_description{width:100%;}
    .nosotros_description{padding:3rem 2rem;}

    .chef{flex-wrap:wrap;flex-direction:column;}
    .chef_description{padding:3rem;}
    .chef_description, .chef img{width:100%;}

    .sabores_description{padding:3rem;}
    .sabores_description .sabores{max-width:37rem;}

    .instalaciones{flex-direction:column-reverse;}
    .instalaciones_description, .instalaciones img{width:100%;}
    .instalaciones_description{padding:4rem 3rem;}
    .instalaciones_description div{margin-top:0;}

    .menu{padding:4rem 3rem 2rem 3rem;}
    .menu a{font-size:1.2rem;}
    .menu div{width:100%;}
    .menu_elements{padding:2rem 3rem;}
    .menu_element{width:50%;}
    .menu_element:nth-child(1){padding-left:0;padding-right:.5rem;margin-top:-3rem;}
    .menu_element:nth-child(2){padding-left:.5rem;padding-right:0;}
    .menu_element:nth-child(3){padding-left:0;padding-right:.5rem;margin-top:-2rem;}
    .menu_element:nth-child(4){padding-left:.5rem;padding-right:0;margin-top:2rem;}
    .menu_element_title{font-size:1.2rem;margin-top:.2rem;}
    .menu_link{text-align:left;margin-top:1rem;}

    .encontrarnos{padding:2rem 3rem 3rem 3rem;}
    .encontrarnos_container{margin-bottom:3rem;}
    .map_element{width:calc(33% - .5rem);}
}

@media (max-width:1199px) and (min-width: 900px){
    .header_container{padding:16rem 0 11rem 0;}
    .header_container_logo img{width:45%;}
    .tenedor_r{right:3rem;}
    .tenedor_r img{height:16.5rem;width:auto;}
    .tenedor_l img{width:20rem;height:auto;}

    .nosotros_description{padding:0 1rem 0 0;}

    .chef{margin-top:-3.4rem;}
    .chef_description{padding:3rem;}

    .sabores_description{padding:4rem 3rem 2rem 3rem;}
    .sabores_description .sabores{max-width:37rem;}

    .instalaciones{margin-top:4rem;}
    .instalaciones_description{padding:2rem;}
    .instalaciones_description div{margin-top:0;}

    .menu{padding:4rem 3rem;}
    .menu a{font-size:1.2rem;}
    .menu div{max-width:38rem;}
    .menu_elements{padding:2rem 3rem;}
    .menu_element{padding:0 .3rem;}
    .menu_element_title{font-size:1.2rem;margin-top:.2rem;}

    .encontrarnos{padding:3rem;}
    .encontrarnos_container{margin-bottom:4rem;}
    .map_element{width:18rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .header_container{padding:14rem 0 9rem 0;}
    .header_container_logo img{width:45%;}
    .tenedor_r{right:5rem;}
    .tenedor_r img{height:18rem;width:auto;}
    .tenedor_l img{width:22rem;height:auto;}

    .nosotros_description{padding:4rem;}

    .chef{margin-top:-4.2rem;}
    .chef_description{padding:4rem;}

    .sabores_description{padding:4rem 4rem 2rem 4rem;}
    .sabores_description .sabores{max-width:37rem;}

    .instalaciones{margin-top:4rem;}
    .instalaciones_description{padding:4rem;}

    .menu{padding:4rem 4.5rem;}
    .menu a{font-size:1.2rem;}
    .menu div{max-width:44rem;}
    .menu_elements{padding:2rem 4rem;}
    .menu_element_title{font-size:1.2rem;margin-top:.2rem;}

    .encontrarnos{padding:4rem;}
    .encontrarnos_container{margin-bottom:4rem;}
}

@media (min-width:1400px){
    .header_container{padding:14rem 0 9rem 0;}
    .header_container_logo img{width:55%;}
    .tenedor_r{right:5rem;width:18%;}
    .tenedor_r img{width:100%;height:auto;}
    .tenedor_l{width:25%;height:auto;}
    .tenedor_l img{width:100%;height:auto;}

    .nosotros_description{padding:4rem;}

    .chef{margin-top:-5.8rem;justify-content:flex-end;}
    .chef_description{padding:4rem;max-width:750px;}

    .sabores_description{padding:4rem;max-width:calc(100% - 8rem);margin:auto;}
    .sabores_description .sabores{max-width:42rem;}
    
    .instalaciones{margin-top:4rem;justify-content:flex-start!important;}
    .instalaciones_description{padding:4rem;max-width:750px;}

    .menu{padding:4rem 4.5rem;}
    .menu a{font-size:1.5rem;}
    .menu div{max-width:50rem;}
    .menu_elements{padding:2rem 4rem;}
    .menu_element_title{font-size:1.3rem;margin-top:.5rem;}
    
    .encontrarnos{padding:4rem;max-width:calc(100% - 8rem);margin:auto;}
    .encontrarnos_container{margin-bottom:4rem;}
    .map_element{width:25rem;padding:0 2rem;}
}